<template>
	<div class="address">
		<van-nav-bar
		        title='香港體檢門店地址'
		        left-arrow
		        fixed
		        class="qjc-nav-bar"
		        @click-left="$router.back()"
		>
			<img @click="$router.push('/')" class="goHome" src="../../assets/images/home.png" slot="right" alt="">
		</van-nav-bar>
		
		<ul class="list">
			<li v-for="(item,index) in address" :key="item.tel" @click="toMap(item)" class="qjc-relative qjc-c qjc-texta-l">
				<span class="qjc-absolute">{{ index+1 }}</span>
				<dl>
					<dt class="qjc-fts-32 qjc-c-dark qjc-ftw-b">{{ item.name }}</dt>
					<dd class="qjc-fts-28">地址：{{ item.address }}</dd>
					<dd class="qjc-fts-28">Tel：{{ item.tel }}</dd>
				</dl>
				<van-icon class="arrow qjc-absolute" name="arrow" />
			</li>
		</ul>
	</div>
</template>

<script>
	import Vue from "vue"
	import { NavBar, Toast, Icon } from "vant"
	Vue.use(NavBar)
	   .use(Toast)
	   .use(Icon);
	
	import { addressList } from "@/js/axios"
	
	export default{
		name: 'homeAddress',
		data (){
			return {
				address: []
			}
		},
		created (){
			addressList().then(res => {
				if(res.status == 1){
					this.address = res.data;
				}else{
					Toast.fail(res.msg);
				}
			});
		},
		methods: {
			toMap (item){
				this.$router.push({
					path: '/address/map',
					name: 'addressMap', 
					params: item
				});
				
				// 防止地图页面刷新不搜索结果，将数据存储在本地
				sessionStorage.setItem('address',JSON.stringify(item));
			}
		}
	}
</script>

<style lang="scss" scoped>
	.goHome{
		width: 0.32rem;
		height: 0.32rem;
		vertical-align: middle;
	}
	.address{
		padding: 0.88rem 0.24rem 0.24rem;
		
		.list{
			li{
				border-bottom: 0.01rem solid #DCDEE5;
				padding: 0.12rem 0.36rem 0.12rem 0.76rem;
				
				span{
					top: 50%;
					left: 0;
					width: 0.36rem;
					height: 0.48rem;
					line-height: 0.44rem;
					margin-top: -0.24rem;
					color: #fff;
					font-size: 0.2rem;
					text-align: center;
					background: url(../../assets/images/zuobiao.png) no-repeat center center;
					background-size: 100% 100%;
				}
				dt{
					line-height: 0.56rem;
				}
				dd{
					color: #777F8F;
					line-height: 0.5rem;
				}
				.arrow{
					top: 50%;
					right: 0;
					font-size: 0.32rem;
					transform: translateY(-50%);
				}
			}
		}
	}
</style>
